import {
  Action,
  ThunkAction,
  combineReducers,
  configureStore,
} from '@reduxjs/toolkit';
import identityReducer from './slices/data/identitySlice';
import legacyAuthorizationReducer from './slices/data/legacyAuthorizationSlice';
import registrationReducer from './slices/data/registrationSlice';
import completeProcessDialogReducer from './slices/ui/completeProcessDialogSlice';
import authUiReducer from './slices/ui/authUiSlice';

const reducer = combineReducers({
  // Data
  identity: identityReducer,
  registration: registrationReducer,
  legacyAuthorization: legacyAuthorizationReducer,
  // UI
  completeProcessDialog: completeProcessDialogReducer,
  authUi: authUiReducer,
});

export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
