import React from 'react';
import Box from '@mui/material/Box/Box';
import { AppBar, styled } from '@mui/material';
import { colors } from '../../../theme';

const NavigationCopmponent = styled('div')(() => ({
  display: 'inline-flex',
  marginLeft: 'auto',
  height: 'fit-content',
  marginTop: '46px',
  marginRight: '24px',
}));

const HeaderComponent = (): JSX.Element => {
  return (
    <AppBar
      sx={{
        backgroundColor: '#FFFFFF',
        flexDirection: 'row',
        boxShadow: 'none',
        borderBottom: `1px solid ${colors.neutral[200]}`,
      }}
      position="relative"
    >
      <Box
        sx={{
          padding: '10px 24px 16px',
          height: '106px',
          width: '225px',
        }}
      >
        <img src="./assets/header/logo.svg?version=2" alt="LogoIcon" />
      </Box>
      <NavigationCopmponent />
    </AppBar>
  );
};

export default HeaderComponent;
