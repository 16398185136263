import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import createPersistedState from 'use-persisted-state';
import { Box } from '@mui/system';
import { verifyUserLoginThunkAction } from '../../app/actions/identity/verifyUserLoginThunkAction';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { LegacyAuthCodeResponse } from '../../app/models/auth';
import { configure } from '../../app/services/axiosService';
import { Applications, environment } from '../../utils';
import { useApplicationConfig } from '../utils';
import { useAuth, useDirectSignInDbNumber } from '../utils/useAuth';
import { stringToBoolean } from '../utils/stringToBoolean';
import {
  setIsInitializing,
  setIsLogout,
} from '../../app/slices/ui/authUiSlice';
import { AuthApiService } from '../../app/services';

const useSessionRappoortUserState = createPersistedState('sessionRappoortUser');
const useAuth0UserEmailState = createPersistedState('auth0UserEmail');

export const AuthContainer = ({ children }): JSX.Element => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const directSignInDbNumber = useDirectSignInDbNumber();
  const authConfig = useApplicationConfig();
  const {
    goToAuth0,
    navigateToAccessDeniedPage,
    navigateToEnterPasswordPage,
    navigateToRapportAuthEnterPassword,
    navigateToChangePassword,
    navigateToImsSignInPage,
    localLogout,
  } = useAuth();
  const [storageRappoortUser, setStorageRappoortUser] =
    useSessionRappoortUserState<LegacyAuthCodeResponse>(null);
  const [storageAuth0UserLogin, setStorageAuth0UserLogin] =
    useAuth0UserEmailState<string>(null);

  const isLogout = useAppSelector((state) => state.authUi.isLogout);
  const isInitializing = useAppSelector((state) => state.authUi.isInitializing);
  const validateLoginResponse = useAppSelector(
    (state) => state.identity.validateLoginResponse,
  );
  const login = useAppSelector((state) => state.identity.login);
  const password = useAppSelector((state) => state.identity.password);
  const legacyAuthorizationResponse = useAppSelector(
    (state) => state.legacyAuthorization,
  );
  const [isValidationFailed, setIsValidationFailed] = useState<boolean>(false);

  const validateAccess = (): boolean => {
    if (
      validateLoginResponse &&
      authConfig?.validateAccess &&
      !authConfig?.validateAccess(validateLoginResponse)
    ) {
      setIsValidationFailed(true);
      return false;
    }
    return true;
  };

  const navigateWithNewRapportAuthCode = (): void => {
    if (!authConfig || !authConfig?.RapportAuth?.CallbackUrl) return;

    AuthApiService.legacyRefreshUserAsync({
      token: storageRappoortUser.refreshToken,
      application: authConfig.Application!,
    }).then((refreshedLegacyAuthorizationResponse) => {
      if (!refreshedLegacyAuthorizationResponse) {
        localLogout();
        return;
      }

      setStorageRappoortUser(refreshedLegacyAuthorizationResponse);
      window.location.href = `${authConfig?.RapportAuth?.CallbackUrl}?code=${legacyAuthorizationResponse.code}`;
    });
  };
  // Init
  useEffect(() => {
    if (isValidationFailed) navigateToAccessDeniedPage();
  }, [isValidationFailed]);

  // Init
  useEffect(() => {
    configure(directSignInDbNumber);
    dispatch(setIsLogout(stringToBoolean(searchParams.get('logout'))));
    dispatch(setIsInitializing(false));
  }, []);

  // Handle signin page
  useEffect(() => {
    if (!validateLoginResponse) return;

    if (validateLoginResponse.ssoId) {
      if (login !== '') {
        setStorageAuth0UserLogin(login);
      }
      if (!validateAccess()) {
        return;
      }
      goToAuth0();
      return;
    }

    if (validateLoginResponse.useSso) {
      navigateToEnterPasswordPage();
      return;
    }

    if (authConfig?.RapportAuth?.CallbackUrl) {
      navigateToRapportAuthEnterPassword();
    } else {
      navigateToEnterPasswordPage();
    }
  }, [validateLoginResponse]);

  // Forward Auth - Auth0
  useEffect(() => {
    if (isInitializing || isLogout || validateLoginResponse) return;

    if (storageAuth0UserLogin) {
      dispatch(verifyUserLoginThunkAction({ login: storageAuth0UserLogin }));
    }
  }, [storageAuth0UserLogin, isInitializing, validateLoginResponse]);

  // Handle rapportauth
  useEffect(() => {
    if (!legacyAuthorizationResponse || !legacyAuthorizationResponse.code)
      return;

    setStorageRappoortUser(legacyAuthorizationResponse);
    if (
      legacyAuthorizationResponse.emailRequired ||
      legacyAuthorizationResponse.passwordExpired
    ) {
      if (login && password) {
        navigateToChangePassword();
      }
    } else {
      window.location.href = `${authConfig?.RapportAuth?.CallbackUrl}?code=${legacyAuthorizationResponse.code}`;
    }
  }, [legacyAuthorizationResponse]);

  // Forward Auth - RapportAuth
  useEffect(() => {
    if (
      !isInitializing ||
      legacyAuthorizationResponse.refreshToken ||
      !authConfig ||
      !storageRappoortUser ||
      !storageRappoortUser.refreshToken
    )
      return;

    if (
      storageRappoortUser.emailRequired ||
      storageRappoortUser.passwordExpired
    ) {
      if (login && password) {
        navigateToChangePassword();
      }
    } else {
      navigateWithNewRapportAuthCode();
    }
  }, [isInitializing, storageRappoortUser, legacyAuthorizationResponse]);

  // Logout
  useEffect(() => {
    if (isInitializing || !authConfig || !isLogout) {
      return;
    }

    if (storageAuth0UserLogin) {
      if (authConfig?.Application === Applications.pulseConnectorManager) {
        window.location.href = authConfig.Auth0?.LogoutUrl ?? '';
        return;
      }

      window.location.href = `https://${environment.Auth0.Domain}/v2/logout?returnTo=${authConfig.Auth0?.LogoutUrl}&client_id=${authConfig.Auth0?.ClientId}`;
      setStorageAuth0UserLogin(null);
      setStorageRappoortUser(null);
      return;
    }

    if (storageRappoortUser) {
      localLogout();
    }

    navigateToImsSignInPage();
  }, [
    isInitializing,
    isLogout,
    authConfig,
    storageAuth0UserLogin,
    setStorageAuth0UserLogin,
  ]);

  if (authConfig === undefined) {
    return <Box>Error: Some configuration missed in URL params</Box>;
  }

  if (isLogout) {
    return <Box>Logout in process</Box>;
  }

  if (
    !login &&
    !isValidationFailed &&
    (storageAuth0UserLogin || storageRappoortUser)
  ) {
    return <Box>Forwarding auth</Box>;
  }

  return children;
};
