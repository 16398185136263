import React from 'react';
import { Box } from '@mui/material';
import ValidationMessage from './ValidationMessage';

interface Props {
  text: string;
  show: boolean;
  isRed?: boolean;
}

export function ValidationBox({
  text,
  show,
  isRed = true,
}: Props): JSX.Element {
  return (
    <Box
      sx={{ padding: '5px 20px 0px 0px' }}
      visibility={show ? 'initial' : 'visible'}
      display={show ? 'initial' : 'none'}
    >
      <ValidationMessage red={isRed} text={text} />
    </Box>
  );
}
