/* eslint-disable */
import React from 'react';
import { Button, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useAppSelector } from '../../../../app/hooks';
import { PageContentContainer } from '../../../../components/Container';
import { colors } from '../../../../theme';
import { useAuth } from '../../../utils/useAuth';

export const AccessForbidenComponent = (): JSX.Element => {
  const { localLogout } = useAuth();
  const onButtonClick = (): void => {
    localLogout();
  };

  const userProfile = useAppSelector(
    (state) => state.identity.validateLoginResponse,
  );

  return (
    <PageContentContainer>
      <Box>
        <Typography variant="h5">Forbidden:</Typography>
        <Typography
          variant="h5"
          sx={{ paddingBottom: '33px', color: colors.neutral[600] }}
        >
          Your account, {userProfile?.email || userProfile?.userId} is not
          authorized to view this page. Make sure the URL is correct and your
          account has access.
        </Typography>
      </Box>
      <Button
        variant="outlined"
        onClick={onButtonClick}
        sx={{ marginTop: '12px', textTransform: 'none' }}
      >
        Sign out and login with different account
      </Button>
    </PageContentContainer>
  );
};
