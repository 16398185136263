import {
  Breakpoint,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import React from 'react';
import { CloseIcon } from '../Icons';
import * as styles from './styles';

interface Props {
  open: boolean;
  title: string;
  children?: React.ReactNode;
  actions?: React.ReactNode;
  maxWidth?: false | Breakpoint | undefined;
  onClose?: () => void;
}

const ModalComponent = (props: Props): JSX.Element => {
  return (
    <Dialog open={props.open} maxWidth={props.maxWidth ? props.maxWidth : 'xl'}>
      <DialogTitle sx={styles.titleBox}>
        <Typography sx={styles.title}>{props.title}</Typography>
        {props.onClose && (
          <IconButton sx={styles.modalCloseButton} onClick={props.onClose}>
            <CloseIcon />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent sx={styles.content}>{props.children}</DialogContent>
      <DialogActions sx={styles.actions}>{props.actions}</DialogActions>
    </Dialog>
  );
};

export default ModalComponent;
