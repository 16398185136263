import * as React from 'react';
import { Box, Link } from '@mui/material';
import * as styles from './styles';

interface Props {
  children?: React.ReactNode;
}

const PageContentContainer = (props: Props): JSX.Element => {
  return (
    <Box sx={styles.card}>
      {props.children}
      <Box sx={{ paddingTop: '60px', fontSize: '11px', textAlign: 'center' }}>
        By continuing, I agree to the{' '}
        <Link href="https://software.covetrus.com/download/1973/">
          Terms of Service
        </Link>{' '}
        and <Link href="https://ccpa.covetrus.com/">Privacy Policy</Link>
      </Box>
    </Box>
  );
};

export default PageContentContainer;
