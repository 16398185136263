import { createAsyncThunk } from '@reduxjs/toolkit';
import { UserProfile } from '../../models/identity/userProfile';
import { UserLoginDto } from '../../models/identity/userLoginDto';
import { IdentityApiService } from '../../services';

export const verifyUserLoginThunkAction = createAsyncThunk(
  'identity/verifyUserLogin',
  async (userLogin: UserLoginDto): Promise<UserProfile | undefined> => {
    return IdentityApiService.verifyUserLoginAsync(userLogin);
  },
);
