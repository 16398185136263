import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  AuthorizationRequest,
  LegacyAuthCodeResponse,
} from '../../models/auth';
import { AuthApiService } from '../../services';

export const legacyAuthorizeUserThunkAction = createAsyncThunk(
  'auth/legacyAuthorizeUser',
  async (
    credentials: AuthorizationRequest,
  ): Promise<LegacyAuthCodeResponse | undefined> => {
    return AuthApiService.legacyAuthorizeUserAsync(credentials);
  },
);
