import axiosInstance from './axiosService';
import {
  AuthorizationRequest,
  AuthorizationTokenRequest,
  LegacyAuthCodeResponse,
} from '../models/auth';

export const legacyAuthorizeUserAsync = async (
  authRequest: AuthorizationRequest,
): Promise<LegacyAuthCodeResponse | undefined> => {
  const url = `/auth/authorize`;
  const response = await axiosInstance.post(url, authRequest, {
    validateStatus: (status: number) =>
      (status >= 200 && status < 300) || status === 404,
  });

  if (response.status === 404) {
    return undefined;
  }

  return response.data;
};

export const legacyRefreshUserAsync = async (
  authRequest: AuthorizationTokenRequest,
): Promise<LegacyAuthCodeResponse | undefined> => {
  const url = `/auth/Refresh`;
  const response = await axiosInstance.post(url, authRequest, {
    validateStatus: (status: number) =>
      (status >= 200 && status < 300) || status === 404 || status === 401,
  });

  if (response.status === 200) {
    return response.data;
  }

  return undefined;
};
