import { createAsyncThunk } from '@reduxjs/toolkit';
import { UserProfile } from '../../models/identity/userProfile';
import { UserCredentials } from '../../models/userCredentialsDto';
import { IdentityApiService } from '../../services';

export const verifyUserCredentialsThunkAction = createAsyncThunk(
  'identity/verifyUserCredentials',
  async (credentials: UserCredentials): Promise<UserProfile | undefined> => {
    return IdentityApiService.verifyUserCredentialsAsync(credentials);
  },
);
