import axiosInstance from './axiosService';
import {
  PasswordChangeRequest,
  PasswordChangeResponse,
} from '../models/passwordManagement/updatePassword';
import {
  ForgotPasswordRequest,
  ForgotPasswordResponse,
} from '../models/passwordManagement/forgotPassword';
import {
  ResetPasswordRequest,
  ResetPasswordResponse,
} from '../models/passwordManagement/resetPassword';

export const updatePasswordAsunc = async (
  request: PasswordChangeRequest,
): Promise<PasswordChangeResponse> => {
  const url = `/PasswordManagement/updatePassword`;
  const response = await axiosInstance.post(url, request, {
    validateStatus: () => true,
  });

  return {
    isSuccess: response.status === 200,
    message: response.data.Error,
  };
};

export const sendForgotPasswordAsunc = async (
  request: ForgotPasswordRequest,
): Promise<ForgotPasswordResponse> => {
  const url = `/PasswordManagement/RequestPasswordReset`;
  const response = await axiosInstance.post(url, request, {
    validateStatus: () => true,
  });

  return {
    isSuccess: response.status === 200,
    message: response.data.Error,
  };
};

export const resetPasswordAsunc = async (
  request: ResetPasswordRequest,
  token: string,
): Promise<ResetPasswordResponse> => {
  const url = `/PasswordManagement/ResetPassword`;
  const response = await axiosInstance.post(url, request, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    validateStatus: () => true,
  });

  return {
    isSuccess: response.status === 200,
    message: response.data.Error,
  };
};
