import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { changePasswordAction } from '../../../../app/slices/data/identitySlice';
import { RapportAuthEnterPasswordComponent } from './RapportAuthEnterPasswordComponent';
import { useAuth } from '../../../utils';
import { RouteTypes } from '../../../../utils/RouteTypes';

const RapportAuthEnterPasswordContainer = (): JSX.Element => {
  const navigate = useNavigate();
  const { navigateToImsSignInPage, rapportAuthByClick } = useAuth();
  const [searchParams] = useSearchParams();

  const dispatch = useAppDispatch();

  const legacyAuthorizationResponse = useAppSelector(
    (state) => state.legacyAuthorization,
  );
  const login = useAppSelector((state) => state.identity.login);
  const password = useAppSelector((state) => state.identity.password);

  const handleChange = (value): void => {
    dispatch(changePasswordAction(value));
  };

  const handleForgotPasswordClick = (): void => {
    navigate({
      pathname: RouteTypes.ForgotPassword,
      search: searchParams.toString(),
    });
  };

  const onButtonClick = (): void => {
    rapportAuthByClick(login, password);
  };

  const onBackButtonClick = (): void => {
    navigateToImsSignInPage();
  };

  useEffect(() => {
    if (!login) {
      navigateToImsSignInPage();
    }
  }, [login]);

  return (
    <RapportAuthEnterPasswordComponent
      password={password}
      validationFailed={legacyAuthorizationResponse.validationFailed}
      onNextButtonClicked={onButtonClick}
      onPasswordChanged={handleChange}
      onForgotButtonClicked={handleForgotPasswordClick}
      onBackButtonClicked={onBackButtonClick}
    />
  );
};

export default RapportAuthEnterPasswordContainer;
