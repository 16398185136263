import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { verifyUserCredentialsThunkAction } from '../../actions/identity/verifyUserCredentialsThunkAction';
import { verifyUserLoginThunkAction } from '../../actions/identity/verifyUserLoginThunkAction';
import { SearchUserProfileResponse } from '../../models/identity/searchUserProfileResponse';
import { UserProfile } from '../../models/identity/userProfile';
import { closeAction } from '../ui/completeProcessDialogSlice';

interface State {
  login: string;
  password: string;
  validationFailed: boolean;
  validateLoginResponse?: SearchUserProfileResponse;
  validateCredentialsResponse?: UserProfile;
}

const initialState: State = {
  login: '',
  password: '',
  validationFailed: false,
  validateLoginResponse: undefined,
  validateCredentialsResponse: undefined,
};

const slice = createSlice({
  name: 'identity',
  initialState,
  reducers: {
    changeLoginAction: (state, action: PayloadAction<string>) => {
      state.login = action.payload;
      if (action.payload === '') state.validationFailed = false;
    },
    changePasswordAction: (state, action: PayloadAction<string>) => {
      state.password = action.payload;
    },
    clearLoginResponse: (state) => {
      state.validateLoginResponse = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      verifyUserLoginThunkAction.fulfilled,
      (state, action: PayloadAction<SearchUserProfileResponse | undefined>) => {
        if (action.payload === undefined) {
          state.validationFailed = true;
          return;
        }
        state.validationFailed = false;
        state.validateLoginResponse = action.payload;
      },
    );
    builder.addCase(verifyUserLoginThunkAction.rejected, (state) => {
      state.validationFailed = true;
    });
    builder.addCase(
      verifyUserCredentialsThunkAction.fulfilled,
      (state, action: PayloadAction<UserProfile | undefined>) => {
        if (action.payload === undefined) {
          state.validationFailed = true;
          return;
        }
        state.validationFailed = false;
        state.validateCredentialsResponse = {
          role: action.payload.role,
          databaseNumber: action.payload.databaseNumber,
          id: action.payload.id,
          email: action.payload.email,
          userId: action.payload.userId,
          name: action.payload.name,
          ssoId: action.payload.ssoId,
          useSso: action.payload.useSso,
          authCode: action.payload.authCode,
        };
      },
    );
    builder.addCase(verifyUserCredentialsThunkAction.rejected, (state) => {
      state.validationFailed = true;
    });
    builder.addCase(closeAction, (state) => {
      state.password = initialState.password;
      state.validationFailed = initialState.validationFailed;
      state.validateLoginResponse = initialState.validateLoginResponse;
      state.validateCredentialsResponse =
        initialState.validateCredentialsResponse;
    });
  },
});

export const { changeLoginAction, changePasswordAction, clearLoginResponse } =
  slice.actions;

export default slice.reducer;
