import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ResetPasswordResponse } from '../../../../app/models/passwordManagement/resetPassword';
import { resetPasswordAsunc } from '../../../../app/services/passwordManagementApiService';
import { useAuth } from '../../../utils';
import {
  ResetPasswordComponnent,
  ResetPasswordRequestInternal,
} from './ResetPasswordComponent';

const ResetPasswordContainer = (): JSX.Element => {
  const { navigateToImsSignInPage } = useAuth();
  const [searchParams] = useSearchParams();
  const [response, setResponse] = useState<ResetPasswordResponse>();

  const handleUpdatePassword = (
    request: ResetPasswordRequestInternal,
  ): void => {
    const token = searchParams.get('token');
    if (!token) {
      setResponse({ isSuccess: false, message: 'Invalid Token' });
      return;
    }

    resetPasswordAsunc(
      {
        newPassword: request.newPassword,
      },
      token,
    ).then((x) => {
      setResponse(x);
      if (x.isSuccess) {
        navigateToImsSignInPage();
      }
    });
  };

  return (
    <ResetPasswordComponnent
      onButtonClick={handleUpdatePassword}
      errorMessage={
        !response?.isSuccess ? response?.message?.split(':').pop() : undefined
      }
    />
  );
};

export default ResetPasswordContainer;
