import { v4 as uuidv4 } from 'uuid';

export interface PasswordValidationResponse {
  id: string;
  isValid: boolean;
  message: string;
}
export const validatePassword = (
  value: string,
): PasswordValidationResponse[] => {
  return [
    {
      id: uuidv4(),
      isValid: !value || (value.length >= 8 && value.length <= 32),
      message: 'Should be between 8 and 32 characters',
    },
    {
      id: uuidv4(),
      isValid: !value || /[0-9]/.test(value),
      message: 'Should contain at least 1 number',
    },
    {
      id: uuidv4(),
      isValid: !value || /[a-zA-Z]/.test(value),
      message: 'Should contain at least 1 letter',
    },
    {
      id: uuidv4(),
      isValid: !value || /[^A-Za-z0-9]/.test(value),
      message: 'Should contain at least 1 special character',
    },
    {
      id: uuidv4(),
      isValid: !value || (/[a-z]/.test(value) && /[A-Z]/.test(value)),
      message: 'Should contain at least 1 uppercase and 1 lowercase character',
    },
  ];
};
