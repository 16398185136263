import React from 'react';
import {
  TextField as MuiTextField,
  OutlinedInputProps,
  TextFieldProps,
  styled,
} from '@mui/material';
import { colors, theme } from '../../theme';

export const CustomTextField = styled((props: TextFieldProps) => (
  <MuiTextField
    InputProps={{ disableUnderline: true } as Partial<OutlinedInputProps>}
    {...props}
  />
))(() => ({
  '& .MuiInputLabel-root': {
    transform: 'translate(17px, 8px)',
    color: colors.neutral[600],
    '&.Mui-error': {
      color: colors.neutral[600],
    },
  },
  '& .MuiInputLabel-shrink': {
    transform: 'translate(17px, 2px) scale(0.7)',
    color: colors.neutral[800],
    '&.Mui-focused': {
      color: colors.utility.default[500],
    },
  },
  '& .MuiInputBase-input': {
    padding: '5px 16px',
    height: '38px',
    boxSizing: 'border-box',
    '&:-webkit-autofill, textarea:-webkit-autofill': {
      boxShadow: `0 0 0 100px ${colors.neutral.white} inset`,
    },
  },
  '& .MuiInputLabel-root + .MuiInputBase-root': {
    '& .MuiInputBase-input': {
      paddingTop: '12px',
      paddingBottom: '3px',
      paddingLeft: '16px',
      paddingRight: '16px',
      '&.MuiSelect-select': {
        paddingRight: '24px',
      },
    },
  },
  '& .MuiSelect-icon': {
    fontSize: '16px',
  },
  '& .MuiFilledInput-root': {
    border: '1px solid',
    borderColor: colors.neutral[400],
    overflow: 'hidden',
    borderRadius: 4,
    backgroundColor: colors.neutral.white,
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    '&:hover': {
      backgroundColor: colors.neutral.white,
    },
    '&.Mui-focused': {
      backgroundColor: colors.neutral.white,
      boxShadow: `0px 0px 0px 3px ${colors.utility.default[200]}`,
      '&.Mui-error': {
        boxShadow: `0px 0px 0px 3px ${colors.utility.danger[200]}`,
      },
      '& .MuiSelect-select': {
        backgroundColor: colors.neutral.white,
      },
    },
    '&.Mui-error': {
      borderColor: colors.utility.danger[500],
    },
  },
}));

export default CustomTextField;
