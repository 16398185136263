import React from 'react';
import { useLocation } from 'react-router-dom';
import { RouteTypes, mapPathToRoute } from '../../utils/RouteTypes';

// eslint-disable-next-line import/exports-last
export const NavigationContext = React.createContext<RouteTypes | null>(null);

const NavigationProvider = ({ children }): JSX.Element => {
  const location = useLocation();
  const route = mapPathToRoute(location.pathname);
  return (
    <NavigationContext.Provider value={route}>
      {children}
    </NavigationContext.Provider>
  );
};

export default NavigationProvider;
