import { RoleTypes } from '../app/models/identity/roleTypes';
import { SearchUserProfileResponse } from '../app/models/identity/searchUserProfileResponse';
import { Applications } from './Applications';

const { config } = window as any;

export const IMS = {
  Url: config.IMS_URL,
  ApiEndpoint: config.IMS_API_URL,
  BaseUrl: config.IMS_BASE_URL,
};

export const getImsLoginUrl = (app: Applications): string => {
  const imsBase = IMS.Url.substr(-1) === '/' ? IMS.Url.slice(0, -1) : IMS.Url;
  return `${imsBase.toLowerCase()}?application=${app}`;
};

export interface AuthConfig {
  Auth0?: {
    ClientId?: string;
    Scope?: string;
    CallbackUrl: string;
    LogoutUrl?: string;
  };
  RapportAuth?: {
    CallbackUrl: string;
  };

  Application?: Applications;
  ReturnTo?: string;
  validateAccess?: (userProfile: SearchUserProfileResponse) => boolean;
}

export const serverShortName = config.SERVER_SHORTNAME;

export const Auth0 = {
  Domain: config.AUTH0_DOMAIN,
  Audience: config.AUTH0_AUDIENCE,
};

export type Consumers = {
  [key in Applications]: AuthConfig;
};

export const Auth0Consumers: Consumers = {
  prwizard: {
    Application: Applications.prWizard,
    Auth0: {
      LogoutUrl: getImsLoginUrl(Applications.prWizard),
      ClientId: config.PRWIZARD_AUTH0_CLIENTID,
      CallbackUrl: config.PRWIZARD_AUTH0_REDIRECT_URL,
      Scope: 'openid offline_access prwizard:access',
    },
    validateAccess: (userProfile) =>
      [
        RoleTypes.Administrator,
        RoleTypes.PartnerAdmin,
        RoleTypes.PrintAdmin,
      ].includes(userProfile.role),
  },
  petid: {
    Application: Applications.petId,
    Auth0: {
      LogoutUrl: getImsLoginUrl(Applications.petId),
      ClientId: config.PETID_AUTH0_CLIENTID,
      CallbackUrl: config.PETID_AUTH0_REDIRECT_URL,
      Scope: 'openid profile email offline_access pet-id:access ims:access',
    },
    validateAccess: (userProfile) =>
      [
        RoleTypes.Administrator,
        RoleTypes.Assistant,
        RoleTypes.PrintAdmin,
      ].includes(userProfile.role),
  },
  practiceadministration: {
    Application: Applications.practiceadministration,
    Auth0: {
      LogoutUrl: getImsLoginUrl(Applications.practiceadministration),
      CallbackUrl: config.PA_AUTH0_REDIRECT_URL,
      ClientId: config.PA_AUTH0_CLIENTID,
      Scope:
        'openid profile email offline_access practice-administration-api:access reports-api:access ims:access',
    },
    validateAccess: (userProfile) =>
      (userProfile.directAccessUserProfile
        ? userProfile.directAccessUserProfile.databaseNumber !== 0
        : userProfile.databaseNumber !== 0) &&
      [
        RoleTypes.Administrator,
        RoleTypes.Assistant,
        RoleTypes.PartnerAdmin,
        RoleTypes.PrintAdmin,
      ].includes(userProfile.role),
  },
  pfb: {
    Application: Applications.pfb,
    Auth0: {
      LogoutUrl: getImsLoginUrl(Applications.pfb),
      CallbackUrl: `${config.PFB_BASE_URL}auth/auth0callback.aspx`,
      ClientId: config.PFB_AUTH0_CLIENTID,
      Scope: 'openid profile offline_access',
    },
    RapportAuth: {
      CallbackUrl: `${config.PFB_BASE_URL}auth/LegacyAuthCallback.aspx`,
    },
    validateAccess: (userProfile) =>
      (userProfile.directAccessUserProfile
        ? userProfile.directAccessUserProfile.databaseNumber !== 0
        : userProfile.databaseNumber !== 0) &&
      [
        RoleTypes.Administrator,
        RoleTypes.Assistant,
        RoleTypes.PartnerAdmin,
        RoleTypes.PrintAdmin,
      ].includes(userProfile.role),
  },
  mediacenter: {
    Application: Applications.mediacenter,
    Auth0: {
      LogoutUrl: getImsLoginUrl(Applications.mediacenter),
      CallbackUrl: `${config.MEDIACENTER_BASE_URL}auth/auth0Callback.aspx`,
      ClientId: config.MEDIACENTER_AUTH0_CLIENTID,
      Scope: 'openid profile offline_access',
    },
    RapportAuth: {
      CallbackUrl: `${config.MEDIACENTER_BASE_URL}auth/LegacyAuthCallback.aspx`,
    },
    validateAccess: (userProfile) =>
      (userProfile.directAccessUserProfile
        ? userProfile.directAccessUserProfile.databaseNumber !== 0
        : userProfile.databaseNumber !== 0) &&
      [
        RoleTypes.Administrator,
        RoleTypes.Assistant,
        RoleTypes.PartnerAdmin,
        RoleTypes.PrintAdmin,
      ].includes(userProfile.role),
  },
  pfadmin: {
    Application: Applications.pfAdmin,
    Auth0: {
      LogoutUrl: getImsLoginUrl(Applications.pfAdmin),
      CallbackUrl: `${config.PFADMIN_BASE_URL}auth/auth0Callback.aspx`,
      ClientId: config.PFADMIN_AUTH0_CLIENTID,
      Scope: 'openid profile offline_access',
    },
    validateAccess: (userProfile) =>
      userProfile.databaseNumber === 0 &&
      [
        RoleTypes.Administrator,
        RoleTypes.PartnerAdmin,
        RoleTypes.PrintAdmin,
      ].includes(userProfile.role),
  },
  varadmin: {
    Application: Applications.varAdmin,
    Auth0: {
      LogoutUrl: getImsLoginUrl(Applications.varAdmin),
      CallbackUrl: `${config.VAR_ADMIN_BASE_URL}auth/auth0Callback.aspx`,
      ClientId: config.VAR_ADMIN_AUTH0_CLIENTID,
      Scope: 'openid profile offline_access',
    },
    validateAccess: (userProfile) =>
      userProfile.databaseNumber === 0 &&
      [
        RoleTypes.Administrator,
        RoleTypes.PartnerAdmin,
        RoleTypes.PrintAdmin,
      ].includes(userProfile.role),
  },
  pulseconnectormanager: {
    Application: Applications.pulseConnectorManager,
    Auth0: {
      CallbackUrl: `${config.PULSE_CONNECTOR_MANAGER_URL}login`,
      LogoutUrl: `${config.PULSE_CONNECTOR_MANAGER_URL}logout`,
    },
  },
};
