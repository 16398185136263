import { UserProfile } from '../models/identity/userProfile';
import { UserLoginDto } from '../models/identity/userLoginDto';
import axiosInstance from './axiosService';
import { InviteUserRequest } from '../models/identity/inviteUserRequest';
import { UserCredentials } from '../models/userCredentialsDto';

export const verifyUserLoginAsync = async (
  userLogin: UserLoginDto,
): Promise<UserProfile | undefined> => {
  const url = `/api/Identity/verify/login`;
  const response = await axiosInstance.post(url, userLogin, {
    validateStatus: (status: number) =>
      (status >= 200 && status < 300) || status === 404,
  });
  if (response.status === 404) {
    return undefined;
  }
  return response.data;
};

export const verifyUserCredentialsAsync = async (
  userCredentials: UserCredentials,
): Promise<UserProfile | undefined> => {
  const url = `/api/Identity/verify/credentials`;
  const response = await axiosInstance.post(url, userCredentials, {
    validateStatus: (status: number) =>
      (status >= 200 && status < 300) || status === 404,
  });
  if (response.status === 404) {
    return undefined;
  }
  return response.data;
};

export const inviteUserAsync = (
  verifyUserExistsDto: InviteUserRequest,
): Promise<string | undefined> => {
  const url = `/api/Identity/invite`;
  return axiosInstance
    .post(url, verifyUserExistsDto)
    .then((response) => response.data);
};
