import React from 'react';
import { styled } from '@mui/material/styles';
import MuiButton, {
  ButtonProps as OriginButtonProps,
} from '@mui/material/Button';
import { Box } from '@mui/material';

interface ButtonProps {
  handleClick?: () => void;
  icon?: JSX.Element;
  iconRight?: boolean;
}

interface StyledButtonProps extends OriginButtonProps {
  iconRight?: boolean;
}

type Props = ButtonProps & OriginButtonProps;

const StyledButton = styled(MuiButton, {
  shouldForwardProp: (prop) => prop !== 'iconRight',
})<StyledButtonProps>(({ iconRight }) => ({
  textTransform: 'unset',
  '& .buttonContent': {
    flexGrow: 1,
  },
  '& .buttonIcon': {
    display: 'flex',
    alignItems: 'center',
    marginRight: '10px',
  },
  ...(iconRight && {
    flexDirection: 'row-reverse',
    '& .buttonIcon': {
      display: 'flex',
      marginRight: '0',
      marginLeft: '10px',
    },
  }),
}));

export default function Button(props: Props): JSX.Element {
  const { icon, children, handleClick, ...other } = props;
  return (
    <StyledButton onClick={handleClick} {...other}>
      {icon && (
        <Box className="buttonIcon" component="span">
          {icon}
        </Box>
      )}
      <Box className="buttonContent" component="span">
        {children}
      </Box>
    </StyledButton>
  );
}
