import { Palette, SxProps } from '@mui/material';
import { theme } from '../../theme';

const colors: Palette = theme.palette;

export const warning: SxProps = {
  display: 'flex',
  alignItems: 'center',
  fontFamily: 'Mulish',
  fontStyle: 'normal',
  fontWeight: '600',
  fontSize: '12px',
  lineHeight: '24px',
};

export const warningGrey: SxProps = {
  color: colors.brand.primary[500],
};

export const warningRed: SxProps = {
  color: colors.utility.danger[600],
};
