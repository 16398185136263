import * as environment from './environment';

export enum RouteTypes {
  SignIn = '/',
  EnterPassword = '/EnterPassword',
  RapportAuthEnterPassword = '/RapportAuthEnterPassword',
  SignUp = '/SignUp',
  ChangePassword = '/ChangePassword',
  ForgotPassword = '/ForgotPassword',
  ResetPassword = '/ResetPassword',
  AccessForbiden = '/AccessForbiden',
}

export const mapPathToRoute = (path: string): RouteTypes | null => {
  const route = path.replace(environment.IMS.BaseUrl, '') as RouteTypes;
  return route;
};
