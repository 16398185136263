import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { changePasswordAction } from '../../../../app/slices/data/identitySlice';
import { RouteTypes } from '../../../../utils/RouteTypes';
import { MigrationEnterPasswordComponent } from './MigrationEnterPasswordComponent';
import { verifyUserCredentialsThunkAction } from '../../../../app/actions/identity/verifyUserCredentialsThunkAction';
import { useApplicationConfig, useAuth } from '../../../utils';
import { Applications } from '../../../../utils';

const MigrationEnterPasswordContainer = (): JSX.Element => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const authConfig = useApplicationConfig();
  const dispatch = useAppDispatch();
  const { navigateToImsSignInPage } = useAuth();

  const validateCredentialsResponse = useAppSelector(
    (state) => state.identity.validateCredentialsResponse,
  );
  const validateLoginResponse = useAppSelector(
    (state) => state.identity.validateLoginResponse,
  );
  const validationFailed = useAppSelector(
    (state) => state.identity.validationFailed,
  );

  const login = useAppSelector((state) => state.identity.login);
  const password = useAppSelector((state) => state.identity.password);

  const handleChange = (value): void => {
    dispatch(changePasswordAction(value));
  };

  const handleForgotPasswordClick = (): void => {
    navigate({
      pathname: RouteTypes.ForgotPassword,
      search: searchParams.toString(),
    });
  };

  const onButtonClick = (): void => {
    if (
      authConfig?.Application === Applications.pfb &&
      !validateLoginResponse?.useSso
    )
      return;

    dispatch(verifyUserCredentialsThunkAction({ login, password }));
  };

  const onBackButtonClick = (): void => {
    navigateToImsSignInPage();
  };

  useEffect(() => {
    if (!validateCredentialsResponse) return;

    navigate(
      {
        pathname: RouteTypes.SignUp,
        search: searchParams.toString(),
      },
      {
        replace: true,
      },
    );
  }, [validateCredentialsResponse, navigate, searchParams]);

  return (
    <MigrationEnterPasswordComponent
      login={login}
      password={password}
      validationFailed={validationFailed}
      onNextButtonClicked={onButtonClick}
      onPasswordChanged={handleChange}
      onForgotButtonClicked={handleForgotPasswordClick}
      onBackButtonClicked={onBackButtonClick}
    />
  );
};

export default MigrationEnterPasswordContainer;
