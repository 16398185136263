/* eslint-disable max-len */
import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export default function CloseIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon viewBox="0 0 11 11" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.342 2.15082C10.6263 1.86718 10.6263 1.40609 10.342 1.12245C10.0583 0.838089 9.59797 0.838089 9.3136 1.12245L5.73251 4.70354L2.15069 1.12245C1.86706 0.838089 1.40597 0.838089 1.12233 1.12245C0.837967 1.40609 0.837967 1.86718 1.12233 2.15082L4.70342 5.73263L1.12233 9.31373C0.837967 9.59809 0.837967 10.0585 1.12233 10.3421C1.40597 10.6265 1.86706 10.6265 2.15069 10.3421L5.73251 6.761L9.3136 10.3421C9.59797 10.6265 10.0583 10.6265 10.342 10.3421C10.6263 10.0585 10.6263 9.59809 10.342 9.31373L6.76088 5.73263L10.342 2.15082Z"
      />
    </SvgIcon>
  );
}
