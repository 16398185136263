import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { inviteUserThunkAction } from '../../actions/identity/inviteUserThunkAction';

interface State {
  name: string;
  email: string;
  error?: {
    code?: number;
    message?: string;
  };
}

const initialState: State = {
  name: '',
  email: '',
};

const slice = createSlice({
  name: 'registration',
  initialState,
  reducers: {
    changeNameAction: (state, action: PayloadAction<string>) => {
      state.name = action.payload;
    },
    changeEmailAction: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(inviteUserThunkAction.fulfilled, (state) => {
      state.error = undefined;
    });
    builder.addCase(inviteUserThunkAction.rejected, (state, action) => {
      const { status, data } = action.payload as AxiosResponse;
      state.error = {
        code: status,
        message: data.Error,
      };
    });
  },
});

export const { changeNameAction, changeEmailAction } = slice.actions;

export default slice.reducer;
