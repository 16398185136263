import axios, { AxiosInstance } from 'axios';
import { environment } from '../../utils';

const axiosInstance: AxiosInstance = axios.create({
  baseURL: environment.IMS.ApiEndpoint,
});

export const configure = (dbNumber: number | undefined): void => {
  axiosInstance.interceptors.request.use((config) => {
    config.headers['Content-Type'] = 'application/json';
    config.headers.DbNumber = dbNumber;
    return config;
  });
};

export const buildQueryParams = (object: object): string => {
  return Object.keys(object)
    .filter(
      (key) =>
        object[key] !== undefined && object[key] !== null && object[key] !== '',
    )
    .map((key) => `${key}=${object[key]}`)
    .join('&');
};

export default axiosInstance;
