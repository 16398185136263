import { createSlice } from '@reduxjs/toolkit';

interface State {
  isLogout: boolean;
  isInitializing: boolean;
}

const initialState: State = {
  isInitializing: true,
  isLogout: false,
};

const slice = createSlice({
  name: 'ui/auth',
  initialState,
  reducers: {
    setIsLogout(state, value) {
      state.isLogout = value.payload;
    },
    setIsInitializing(state, value) {
      state.isInitializing = value.payload;
    },
  },
});
export const { setIsLogout, setIsInitializing } = slice.actions;

export default slice.reducer;
