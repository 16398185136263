import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { legacyAuthorizeUserThunkAction } from '../../actions/auth';
import { LegacyAuthCodeResponse } from '../../models/auth';

interface State {
  validationFailed: boolean;
  code?: string;
  refreshToken?: string;
  passwordExpired?: boolean;
  emailRequired?: boolean;
}

const initialState: State = {
  validationFailed: false,
};

const slice = createSlice({
  name: 'legacyAuthorization',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      legacyAuthorizeUserThunkAction.fulfilled,
      (state, action: PayloadAction<LegacyAuthCodeResponse | undefined>) => {
        if (action.payload === undefined) {
          state.validationFailed = true;
          return;
        }
        state.validationFailed = false;
        state.code = action.payload?.code;
        state.refreshToken = action.payload?.refreshToken;
        state.passwordExpired = action.payload?.passwordExpired;
        state.emailRequired = action.payload?.emailRequired;
        localStorage.setItem('sessionRappoortUser', JSON.stringify(state));
      },
    );
    builder.addCase(legacyAuthorizeUserThunkAction.rejected, (state) => {
      state.validationFailed = true;
    });
  },
});

export default slice.reducer;
