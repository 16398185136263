import React from 'react';
import { ThemeProvider } from '@mui/material';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { store } from './app/store';
import * as serviceWorker from './serviceWorker';
import { theme } from './theme';
import NavigationProvider from './ui/navigation/NavigationProvider';
import { environment } from './utils';
import { AuthContainer } from './ui/navigation/AuthContainer';

const container = document.getElementById('root');

const render = (): void => {
  const auth0UserEmail = localStorage.getItem('auth0UserEmail');
  if (
    auth0UserEmail &&
    auth0UserEmail !== 'null' &&
    !auth0UserEmail.includes('"')
  ) {
    localStorage.setItem('auth0UserEmail', `"${auth0UserEmail}"`);
  }

  const sessionRappoortUser = localStorage.getItem('sessionRappoortUser');
  if (
    sessionRappoortUser &&
    sessionRappoortUser !== 'null' &&
    !sessionRappoortUser.includes('"')
  ) {
    localStorage.setItem('sessionRappoortUser', `"${sessionRappoortUser}"`);
  }

  if (container) {
    createRoot(container).render(
      <Provider store={store}>
        <BrowserRouter basename={environment.IMS.BaseUrl}>
          <NavigationProvider>
            <ThemeProvider theme={theme}>
              <AuthContainer>
                <App />
              </AuthContainer>
            </ThemeProvider>
          </NavigationProvider>
        </BrowserRouter>
      </Provider>,
    );
  }
};

render();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
