import React, { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { PasswordChangeResponse } from '../../../../app/models/passwordManagement/updatePassword';
import { updatePasswordAsunc } from '../../../../app/services/passwordManagementApiService';
import {
  changePasswordAction,
  clearLoginResponse,
} from '../../../../app/slices/data/identitySlice';
import { useAuth } from '../../../utils';
import {
  ChangePasswordComponnent,
  PasswordChangeRequestInternal,
} from './ChangePasswordComponent';

const ChangePasswordContainer = (): JSX.Element => {
  const { navigateToImsSignInPage } = useAuth();
  const dispatch = useAppDispatch();
  const login = useAppSelector((state) => state.identity.login);
  const password = useAppSelector((state) => state.identity.password);
  const legacyAuthorizationResponse = useAppSelector(
    (state) => state.legacyAuthorization,
  );
  const [response, setResponse] = useState<PasswordChangeResponse>();

  useEffect(() => {
    if (!login || !password || !legacyAuthorizationResponse.emailRequired) {
      navigateToImsSignInPage();
    }
  }, [login, password, legacyAuthorizationResponse]);

  const handleUpdatePassword = (
    request: PasswordChangeRequestInternal,
  ): void => {
    updatePasswordAsunc({
      credentials: {
        login,
        password,
      },
      email: request.email,
      newPassword: request.newPassword,
    }).then((x) => {
      setResponse(x);
      if (x.isSuccess) {
        dispatch(changePasswordAction(''));
        dispatch(clearLoginResponse());
        navigateToImsSignInPage();
      }
    });
  };

  return (
    <ChangePasswordComponnent
      onButtonClick={handleUpdatePassword}
      password={password}
      errorMessage={
        !response?.isSuccess ? response?.message?.split(':').pop() : undefined
      }
      emailRequired={legacyAuthorizationResponse.emailRequired ?? false}
    />
  );
};

export default ChangePasswordContainer;
