import { SxProps } from '@mui/material';
import { colors } from '../../theme';

export const titleBox: SxProps = {
  display: 'flex',
  justifyContent: 'space-between',
  mt: '27px',
  pt: '0px',
  pb: '0px',
};

export const title: SxProps = {
  fontSize: '14px',
  color: colors.brand.primary[800],
};

export const modalCloseButton: SxProps = {
  height: '24px',
  width: '24px',
  mr: '8px',
  color: colors.neutral[600],
  '& .MuiSvgIcon-root': {
    fontSize: '14px',
  },
};

export const content: SxProps = {
  overflow: 'auto',
};

export const actions: SxProps = {
  padding: '18px 24px',
};
