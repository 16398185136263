import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { InviteUserRequest } from '../../models/identity/inviteUserRequest';
import { IdentityApiService } from '../../services';
import { RootState } from '../../store';

export const inviteUserThunkAction = createAsyncThunk(
  'identity/inviteUser',
  async (_, { getState, rejectWithValue }) => {
    const state = getState() as RootState;

    const inviteUserRequest: InviteUserRequest = {
      credentials: {
        login: state.identity.login,
        password: state.identity.password,
      },
      name: state.registration.name,
      email: state.registration.email,
    };
    try {
      const res = await IdentityApiService.inviteUserAsync(inviteUserRequest);
      return res;
    } catch (err) {
      return rejectWithValue((err as AxiosError).response);
    }
  },
);
