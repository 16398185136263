import { Palette, Theme, createTheme } from '@mui/material';

const createAppTheme = (): Theme => {
  const theme = createTheme({
    palette: {
      brand: {
        primary: {
          '100': '#F3F8FA',
          '200': '#E1EEF3',
          '300': '#CFE3EC',
          '400': '#A4C3D7',
          '500': '#6E97BC',
          '600': '#406CA1',
          '700': '#1B4183',
          '800': '#021660',
          '900': '#010D39',
        },
        secondary: {
          '100': '#EDFCFA',
          '200': '#D1F5F1',
          '300': '#A4E2DE',
          '400': '#72D0CD',
          '500': '#27BDBE',
          '600': '#10A1A9',
          '700': '#01818C',
          '800': '#00606D',
          '900': '#00414D',
        },
        tertiary: {
          '100': '#FFF4F0',
          '200': '#FFD7C9',
          '300': '#FFAD98',
          '400': '#FB7760',
          '500': '#ED3030',
          '600': '#C52222',
          '700': '#9A1414',
          '800': '#770000',
          '900': '#550000',
        },
      },
      utility: {
        default: {
          '100': '#E8F7FF',
          '200': '#B3E0FF',
          '300': '#75C1FF',
          '400': '#46A2FB',
          '500': '#1474E5',
          '600': '#0E59C4',
          '700': '#0A3FA2',
          '800': '#00307A',
          '900': '#041F55',
        },
        success: {
          '100': '#EAF6F3',
          '200': '#CCF2D9',
          '300': '#96E6B2',
          '400': '#6ED299',
          '500': '#41B87F',
          '600': '#159F6C',
          '700': '#00855D',
          '800': '#015C43',
          '900': '#133A33',
        },
        warning: {
          '100': '#FAFAF8',
          '150': '#FFFCEF',
          '200': '#FFF8D6',
          '300': '#FFEBAC',
          '400': '#FFD779',
          '500': '#EFB51B',
          '600': '#D0970D',
          '700': '#B47C00',
          '800': '#956400',
          '900': '#7C5000',
        },
        danger: {
          '100': '#FEF0EE',
          '200': '#FFCAC7',
          '300': '#FF827D',
          '400': '#F24C59',
          '500': '#D8193B',
          '600': '#B01332',
          '700': '#890F28',
          '800': '#640B1D',
          '900': '#410712',
        },
      },
      neutral: {
        white: '#FFFFFF',
        '100': '#F7F7F7',
        '150': '#F1F1F1',
        '200': '#E8E8E8',
        '250': '#D9D9D9',
        '300': '#CACACA',
        '400': '#A9A9A9',
        '500': '#919090',
        '600': '#6E6E6E',
        '700': '#484848',
        '800': '#2C2C2C',
        black: '#191919',
      },
      seasonal: {
        purple: {
          '100': '#FAF0FB',
          '200': '#E8D1EB',
          '300': '#D6B2DA',
          '400': '#C393CA',
          '500': '#B075BA',
          '600': '#9D57AA',
          '700': '#7F3A8D',
          '800': '#612173',
          '900': '#451454',
        },
        orange: {
          '100': '#FFF0E4',
          '200': '#FFD7BB',
          '300': '#FFBE95',
          '400': '#FBA46F',
          '500': '#F48B52',
          '600': '#E86A31',
          '700': '#CE480B',
          '800': '#AC3803',
          '900': '#912E00',
        },
        pink: {
          '100': '#FFE2F0',
          '200': '#FFC2E0',
          '300': '#FF8AC4',
          '400': '#F4489E',
          '500': '#C9247A',
          '600': '#A31961',
          '700': '#85144F',
          '800': '#620337',
          '900': '#460127',
        },
      },
      annotations: {
        '500': '#7B61FF',
      },
    },
    typography: {
      fontFamily: 'Mulish',
      fontSize: 14,
      button: {
        fontSize: '1rem',
        fontWeight: 700,
      },
      h1: {
        fontSize: '1rem',
        fontWeight: 700,
      },
      h2: {
        fontStyle: 'normal',
        fontSize: '18px',
        fontWeight: '400',
        lineHeight: '24px',
      },
      h3: {
        fontStyle: 'normal',
        fontSize: '14px',
        fontWeight: '700',
        lineHeight: '20px',
      },
    },
  });

  return createTheme({
    components: {
      MuiTypography: {
        styleOverrides: {
          root: {
            color: theme.palette.text.primary,
          },
        },
        variants: [
          {
            props: { variant: 'h5' },
            style: { fontSize: '18px' },
          },
        ],
      },
      MuiButton: {
        variants: [
          {
            props: { variant: 'outlined' },
            style: {
              color: theme.palette.utility.default[500],
              borderColor: theme.palette.utility.default[500],
            },
          },
          {
            props: { variant: 'contained' },
            style: {
              color: theme.palette.neutral.white,
              backgroundColor: theme.palette.utility.default[500],
            },
          },
          {
            props: { variant: 'containedGrey' },
            style: {
              color: theme.palette.neutral[400],
              backgroundColor: theme.palette.neutral[200],
              '&:hover': {
                color: theme.palette.neutral[400],
                backgroundColor: theme.palette.neutral[200],
              },
            },
          },
        ],
      },
      MuiListItem: {
        styleOverrides: {
          root: {
            fontFamily: theme.typography.fontFamily,
            fontSize: theme.typography.fontSize,
          },
        },
      },
    },
    typography: theme.typography,
    palette: theme.palette,
  });
};

export const theme = createAppTheme();

export const colors: Palette = theme.palette;
