import { SxProps } from '@mui/system';
import { colors } from '../../theme';

export const card: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  width: '400px',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: colors.neutral['200'],
  textDecoration: 'none',
  background: colors.neutral.white,
  padding: '24px',
};
