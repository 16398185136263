import React from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import RouterComponent from './ui/navigation/RouterComponent';
import './App.css';
import { HeaderContainer } from './ui/common';
import CompleteProcessDialog from './ui/popups/CompleteProcessDialog/CompleteProcessDialog';

const App = (): JSX.Element => {
  return (
    <div className="App">
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <HeaderContainer />
        <Container
          component="main"
          maxWidth={false}
          sx={{
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <RouterComponent />
        </Container>
      </Box>
      <CompleteProcessDialog />
    </div>
  );
};

export default App;
