import {
  createSearchParams,
  useSearchParams,
  useNavigate,
} from 'react-router-dom';
import createPersistedState from 'use-persisted-state';
import { legacyAuthorizeUserThunkAction } from '../../app/actions/auth';
import { verifyUserLoginThunkAction } from '../../app/actions/identity/verifyUserLoginThunkAction';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { LegacyAuthCodeResponse } from '../../app/models/auth';
import { closeAction } from '../../app/slices/ui/completeProcessDialogSlice';
import { environment } from '../../utils';
import { RouteTypes } from '../../utils/RouteTypes';
import { useApplicationConfig } from './useApplicationConfig';

const useSessionRappoortUserState = createPersistedState('sessionRappoortUser');
const useAuth0UserEmailState = createPersistedState('auth0UserEmail');
export const useDirectSignInDbNumber = (): number | undefined => {
  const [searchParams] = useSearchParams();

  const directSignInDbNumber =
    searchParams.get('dbNumber') ??
    searchParams.get('dbnumber') ??
    searchParams.get('DatabaseNumber') ??
    searchParams.get('databaseNumber') ??
    searchParams.get('databasenumber');

  return directSignInDbNumber ? +directSignInDbNumber : undefined;
};

export const useAuth = (): any => {
  const dispatch = useAppDispatch();
  const directSignInDbNumber = useDirectSignInDbNumber();
  const authConfig = useApplicationConfig();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [, setSessionRappoortUser] =
    useSessionRappoortUserState<LegacyAuthCodeResponse>(null);
  const [, setAuth0UserEmail] = useAuth0UserEmailState<string>(null);
  const identityLogin = useAppSelector((state) => state.identity.login);
  const validateByClick = (login: string): void => {
    if (login) {
      dispatch(verifyUserLoginThunkAction({ login }));
    }
  };

  const rapportAuthByClick = (login: string, password: string): void => {
    if (login && password) {
      dispatch(
        legacyAuthorizeUserThunkAction({
          userName: login,
          password,
          application: authConfig!.Application!,
        }),
      );
    }
  };

  const localLogout = (): void => {
    if (!authConfig) return;

    setAuth0UserEmail(null);
    setSessionRappoortUser(null);
    const queryParamsObj = {
      application: authConfig.Application!,
      ...(directSignInDbNumber
        ? { dbNumber: directSignInDbNumber.toString() }
        : {}),
    };
    const localSearchParams = createSearchParams(queryParamsObj).toString();
    window.location.href = `${environment.IMS.Url}?${localSearchParams}`;
  };

  const goToAuth0 = (): void => {
    if (!authConfig || !authConfig.Auth0) {
      return;
    }

    const dbParamOptional = directSignInDbNumber
      ? `?dbNumber=${directSignInDbNumber}`
      : '';
    const loginHintParamOptional = identityLogin
      ? `&login_hint=${encodeURIComponent(identityLogin)}`
      : '';

    window.location.href = `https://${environment.Auth0.Domain}/authorize?client_id=${authConfig.Auth0.ClientId}&respones_mode=query&redirect_uri=${authConfig.Auth0.CallbackUrl}${dbParamOptional}&response_type=code&scope=${authConfig.Auth0.Scope}${loginHintParamOptional}`;
  };

  const navigateToAccessDeniedPage = (): void => {
    navigate(
      {
        pathname: RouteTypes.AccessForbiden,
        search: searchParams.toString(),
      },
      {
        replace: true,
      },
    );
  };

  const navigateToEnterPasswordPage = (): void => {
    navigate(
      {
        pathname: RouteTypes.EnterPassword,
        search: searchParams.toString(),
      },
      {
        replace: true,
      },
    );
  };

  const navigateToChangePassword = (): void => {
    navigate(
      {
        pathname: RouteTypes.ChangePassword,
        search: searchParams.toString(),
      },
      {
        replace: true,
      },
    );
  };

  const navigateToRapportAuthEnterPassword = (): void => {
    navigate(
      {
        pathname: RouteTypes.RapportAuthEnterPassword,
        search: searchParams.toString(),
      },
      {
        replace: true,
      },
    );
  };

  const navigateToImsSignInPage = (): void => {
    navigate(
      {
        pathname: RouteTypes.SignIn,
        search: searchParams.toString(),
      },
      {
        replace: true,
      },
    );
  };

  const completeProgressDialog = (): void => {
    dispatch(closeAction());
    navigateToImsSignInPage();
  };

  return {
    validateUser: validateByClick,
    localLogout,
    goToAuth0,
    navigateToAccessDeniedPage,
    navigateToEnterPasswordPage,
    navigateToRapportAuthEnterPassword,
    navigateToImsSignInPage,
    completeProgressDialog,
    rapportAuthByClick,
    navigateToChangePassword,
  };
};
