import React from 'react';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { Button } from '../../../../components/Buttons';
import { PageContentContainer } from '../../../../components/Container';
import { Input } from '../../../../components/Inputs';
import { colors } from '../../../../theme';
import { inviteUserThunkAction } from '../../../../app/actions/identity/inviteUserThunkAction';
import {
  changeEmailAction,
  changeNameAction,
} from '../../../../app/slices/data/registrationSlice';
import { ValidationBox } from '../../../../components/ValidationMessage';

const SignUpComponent = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const registration = useAppSelector((state) => state.registration);

  const onButtonClick = (): void => {
    dispatch(inviteUserThunkAction());
  };

  return (
    <PageContentContainer>
      <Box>
        <Typography variant="h5">
          Please Sign Up for Covetrus Single Sign On
        </Typography>
        <Typography
          variant="h5"
          sx={{ paddingBottom: '33px', color: colors.neutral[600] }}
        >
          Enter your Name and Email below.
        </Typography>
      </Box>

      <Input
        onChange={(e) => dispatch(changeNameAction(e.target.value))}
        name="name"
        id="name"
        label="Name"
        size="medium"
        value={registration.name}
        InputLabelProps={{ style: { fontSize: 18 } }}
        fullWidth
        sx={{
          mt: '16px',
        }}
        error={!registration.name}
      />

      <Input
        onChange={(e) => dispatch(changeEmailAction(e.target.value))}
        name="email"
        id="email"
        label="Email"
        size="medium"
        value={registration.email}
        InputLabelProps={{ style: { fontSize: 18 } }}
        fullWidth
        sx={{
          mt: '16px',
        }}
        error={!registration.email}
      />

      <Button
        variant="contained"
        onClick={onButtonClick}
        sx={{ marginTop: '12px' }}
        disabled={!registration.name || !registration.email}
      >
        Next
      </Button>
      <ValidationBox
        show={!!registration.error}
        text={registration.error?.message ?? 'Server error'}
      />
    </PageContentContainer>
  );
};

export default SignUpComponent;
