import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Applications, environment } from '../../utils';

export const useApplicationConfig = (): environment.AuthConfig | undefined => {
  const [searchParams] = useSearchParams();

  const getAuthConfig = (): environment.AuthConfig => {
    const applicationParam = searchParams.get('application') ?? 'ims';
    const application = applicationParam as Applications;
    const authConfig = environment.Auth0Consumers[application];
    if (authConfig) {
      authConfig.ReturnTo = searchParams.get('returnTo') ?? '/';
    }
    return authConfig;
  };

  const [state, setState] = useState<environment.AuthConfig>(getAuthConfig());

  useEffect(() => {
    setState(getAuthConfig());
  }, [searchParams]);

  return state;
};
