import React from 'react';
import { Link, Typography } from '@mui/material';
import { ModalComponent } from '../../../components/Modal';
import { useAppSelector } from '../../../app/hooks';
import { colors } from '../../../theme';
import { Button } from '../../../components/Buttons';
import { useAuth } from '../../utils';

const spaceSymbol = ' ';

const CompleteProcessDialog = (): JSX.Element => {
  const { completeProgressDialog } = useAuth();
  const email = useAppSelector((state) => state.registration.email);
  const isOpen = useAppSelector((store) => store.completeProcessDialog.isOpen);

  return isOpen ? (
    <ModalComponent
      maxWidth="xs"
      open={isOpen}
      title="Complete the process"
      actions={
        <Button variant="contained" handleClick={completeProgressDialog}>
          Return to login
        </Button>
      }
    >
      <Typography
        sx={{
          fontSize: '22px',
          color: colors.utility.default[500],
          mt: '8px',
          mb: '16px',
        }}
      >
        Update Password
      </Typography>
      <Typography>
        An email to update your password has been sent to {email}. Please check
        your email to complete this process. If you do not receive this email or
        need to update the email address on file, please contact support at
        {spaceSymbol}
        <Link
          href="tel:855-980-9099"
          sx={{
            textDecoration: 'none',
            color: colors.utility.default[500],
            fontWeight: '500',
          }}
        >
          855-980-9099
        </Link>
        , option 1.
      </Typography>
    </ModalComponent>
  ) : (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <></>
  );
};

export default CompleteProcessDialog;
