import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { RouteTypes } from '../../utils/RouteTypes';
import { SignInContainer } from '../pages/General/SignIn/SignInContainer';
import { MigrationEnterPasswordContainer } from '../pages/Auth0/MigrationEnterPassword';
import SignUpComponent from '../pages/Auth0/SignUp/SignUpComponent';
import ChangePasswordContainer from '../pages/RapportAuth/ChangePassword/ChangePasswordContainer';
import ForgotPasswordContainer from '../pages/General/ForgotPassword/ForgotPasswordContainer';
import ResetPasswordContainer from '../pages/General/ResetPassword/ResetPasswordContainer';
import RapportAuthEnterPasswordContainer from '../pages/RapportAuth/RapportAuthEnterPassword/RapportAuthEnterPasswordContainer';
import { AccessForbidenComponent } from '../pages/General/AccessForbiden';

const RouterComponent = (): JSX.Element => {
  return (
    <Routes>
      <Route path={RouteTypes.SignIn} element={<SignInContainer />} />
      <Route
        path={RouteTypes.EnterPassword}
        element={<MigrationEnterPasswordContainer />}
      />
      <Route
        path={RouteTypes.RapportAuthEnterPassword}
        element={<RapportAuthEnterPasswordContainer />}
      />
      <Route path={RouteTypes.SignUp} element={<SignUpComponent />} />
      <Route
        path={RouteTypes.ChangePassword}
        element={<ChangePasswordContainer />}
      />
      <Route
        path={RouteTypes.ForgotPassword}
        element={<ForgotPasswordContainer />}
      />
      <Route
        path={RouteTypes.ResetPassword}
        element={<ResetPasswordContainer />}
      />
      <Route
        path={RouteTypes.AccessForbiden}
        element={<AccessForbidenComponent />}
      />
      <Route path="*" element={<SignInContainer />} />
    </Routes>
  );
};

export default RouterComponent;
