import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { inviteUserThunkAction } from '../../actions/identity/inviteUserThunkAction';

interface State {
  isOpen: boolean;
}

const initialState: State = { isOpen: false };

const slice = createSlice({
  name: 'ui/completeProcessDialog',
  initialState,
  reducers: {
    closeAction(state) {
      state.isOpen = false;
    },
    openAction(state) {
      state.isOpen = true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      inviteUserThunkAction.fulfilled,
      (state, action: PayloadAction<string | undefined>) => {
        state.isOpen = !!action.payload;
      },
    );
  },
});

export const { closeAction, openAction } = slice.actions;

export default slice.reducer;
