import React, { useState } from 'react';
import {
  ForgotPasswordComponnent,
  ForgotPasswordRequestInternal,
} from './ForgotPasswordComponent';
import { PasswordManagementApiClient } from '../../../../app/services';
import { ForgotPasswordResponse } from '../../../../app/models/passwordManagement/forgotPassword';
import { CompleteResetPasswordDialog } from '../../../popups';
import { useAuth } from '../../../utils';

const ForgotPasswordContainer = (): JSX.Element => {
  const { navigateToImsSignInPage } = useAuth();
  const [response, setResponse] = useState<ForgotPasswordResponse>();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');

  const handleSendForgotPassword = (
    request: ForgotPasswordRequestInternal,
  ): void => {
    PasswordManagementApiClient.sendForgotPasswordAsunc({
      userId: request.userId,
      email: request.email,
    }).then((x) => {
      setResponse(x);
      if (x.isSuccess) {
        setEmail(request.email);
        setIsModalOpen(true);
      }
    });
  };

  const handleModalClose = (): void => {
    setIsModalOpen(false);
    navigateToImsSignInPage();
  };
  if (isModalOpen) {
    return (
      <CompleteResetPasswordDialog
        isOpen={isModalOpen}
        email={email}
        onCloseAction={handleModalClose}
      />
    );
  }
  return (
    <ForgotPasswordComponnent
      onButtonClick={handleSendForgotPassword}
      errorMessage={
        !response?.isSuccess ? response?.message?.split(':').pop() : undefined
      }
    />
  );
};

export default ForgotPasswordContainer;
