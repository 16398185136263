import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { changeLoginAction } from '../../../../app/slices/data/identitySlice';
import { SignInComponent } from './SignInComponent';
import { useAuth } from '../../../utils/useAuth';

export const SignInContainer = (): JSX.Element => {
  const { validateUser } = useAuth();
  const dispatch = useAppDispatch();

  const validationFailed = useAppSelector(
    (state) => state.identity.validationFailed,
  );

  const login = useAppSelector((state) => state.identity.login);

  const onLoginInputChanged = (value: string): void => {
    dispatch(changeLoginAction(value));
  };

  const onNextButtonClicked = (): void => {
    validateUser(login);
  };

  return (
    <SignInComponent
      validationMessageShown={validationFailed}
      login={login}
      onLoginInputChanged={onLoginInputChanged}
      onNextButtonClicked={onNextButtonClicked}
    />
  );
};
