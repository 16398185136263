import React, { KeyboardEvent } from 'react';
import { Link, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { Button } from '../../../../components/Buttons';
import { PageContentContainer } from '../../../../components/Container';
import { Input } from '../../../../components/Inputs';
import { ValidationMessage } from '../../../../components/ValidationMessage';
import { colors } from '../../../../theme';
import { isEmail } from '../../../utils';

interface SignInProps {
  login: string;
  validationMessageShown: boolean;
  onLoginInputChanged: (value: string) => void;
  onNextButtonClicked: () => void;
}

export const SignInComponent = (props: SignInProps): JSX.Element => {
  const handleChange = (e): void => {
    props.onLoginInputChanged(e.target.value);
  };

  const onButtonClick = (): void => {
    props.onNextButtonClicked();
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLElement>): void => {
    if (e.key === 'Enter') props.onNextButtonClicked();
  };

  const validateLogin = (): boolean => {
    return props.login.includes('@') ? isEmail(props.login) : true;
  };

  const isNextButtonDisabled = props.login === '';
  const isLoginValid = validateLogin();
  const loginFieldValidationPart = {
    error: !isLoginValid,
    helperText: !isLoginValid ? 'Incorrect email format' : '',
  };

  return (
    <PageContentContainer>
      <Box>
        <Typography variant="h5">Please Sign In</Typography>
        <Typography
          variant="h5"
          sx={{ paddingBottom: '33px', color: colors.neutral[600] }}
        >
          Enter your User ID/Email below.
        </Typography>
      </Box>
      <Input
        onKeyDown={handleKeyDown}
        onChange={handleChange}
        name="login"
        id="login"
        label="User ID/Email"
        size="medium"
        type="email"
        value={props.login}
        InputLabelProps={{ style: { fontSize: 18 } }}
        fullWidth
        {...loginFieldValidationPart}
      />
      <Box
        sx={{ padding: '5px 20px 0px 0px' }}
        visibility={props.validationMessageShown ? 'visible' : 'initial'}
        display={props.validationMessageShown ? 'initial' : 'none'}
      >
        <ValidationMessage red>
          <>
            User not found - If the problem persists, please contact support at{' '}
            <Link href="tel:+855-980-9099">855-980-9099</Link> Option 1
          </>
        </ValidationMessage>
      </Box>

      <Button
        variant="contained"
        onClick={onButtonClick}
        sx={{ marginTop: '12px' }}
        disabled={isNextButtonDisabled}
      >
        Next
      </Button>
    </PageContentContainer>
  );
};
