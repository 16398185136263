import React, { useState } from 'react';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { Button } from '../../../../components/Buttons';
import { PageContentContainer } from '../../../../components/Container';
import { Input } from '../../../../components/Inputs';
import { colors } from '../../../../theme';
import {
  ValidationBox,
  ValidationMessage,
} from '../../../../components/ValidationMessage';
import { validatePassword } from '../../../utils/passwordValidator';

interface ResetPasswordParams {
  errorMessage?: string;
  onButtonClick: (request: ResetPasswordRequestInternal) => void;
}

export interface ResetPasswordRequestInternal {
  newPassword: string;
  confirmPassword: string;
}

export const ResetPasswordComponnent = (
  props: ResetPasswordParams,
): JSX.Element => {
  const [state, setState] = useState<ResetPasswordRequestInternal>({
    newPassword: '',
    confirmPassword: '',
  });

  const updateField = (
    field: keyof ResetPasswordRequestInternal,
    value?: string,
  ): void => {
    const newObj = {
      ...state,
      [field]: value,
    };
    setState(newObj);
  };
  const passwordValidation = validatePassword(state.newPassword);

  const isNewPasswordFieldsMatch =
    !!state.newPassword && !!state.confirmPassword
      ? state.newPassword === state.confirmPassword
      : true;

  const isAllFieldFilled =
    !!state.newPassword &&
    !!state.confirmPassword &&
    isNewPasswordFieldsMatch &&
    !passwordValidation.some((x) => !x.isValid);

  return (
    <PageContentContainer>
      <Box>
        <Typography variant="h5">Reset your password </Typography>
        <Typography
          variant="h5"
          sx={{ paddingBottom: '33px', color: colors.neutral[600] }}
        >
          Enter your new password.
        </Typography>
      </Box>
      <Input
        onChange={(e) => updateField('newPassword', e.target.value)}
        name="new_password"
        id="new_password"
        label="New password"
        size="medium"
        type="password"
        InputLabelProps={{ style: { fontSize: 18 } }}
        fullWidth
        sx={{
          mt: '16px',
        }}
      />
      <ValidationBox
        show={!!props.errorMessage}
        text={props.errorMessage ?? ''}
      />
      <Box
        sx={{ padding: '5px 20px 0px 0px' }}
        visibility={true ? 'initial' : 'visible'}
        display={true ? 'initial' : 'none'}
      >
        {passwordValidation.map((element) => (
          <ValidationMessage
            key={`ValidationMessage-new_password-rules-${element.id}`}
            red={!element.isValid}
            text={element.message}
          />
        ))}
      </Box>
      <Input
        onChange={(e) => updateField('confirmPassword', e.target.value)}
        name="confirm_new_password"
        id="confirm_new_password"
        label="Confirm new password"
        size="medium"
        type="password"
        InputLabelProps={{ style: { fontSize: 18 } }}
        fullWidth
        sx={{
          mt: '16px',
        }}
      />
      <ValidationBox
        show={!isNewPasswordFieldsMatch}
        text="Passwords must match"
      />

      <Button
        variant="contained"
        onClick={() => props.onButtonClick(state)}
        sx={{ marginTop: '12px' }}
        disabled={!isAllFieldFilled}
      >
        Reset password
      </Button>
    </PageContentContainer>
  );
};
