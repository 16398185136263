import React, { useState } from 'react';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { Button } from '../../../../components/Buttons';
import { PageContentContainer } from '../../../../components/Container';
import { Input } from '../../../../components/Inputs';
import { colors } from '../../../../theme';
import {
  ValidationBox,
  ValidationMessage,
} from '../../../../components/ValidationMessage';
import { isEmail } from '../../../utils';
import { validatePassword } from '../../../utils/passwordValidator';

interface ChangePasswordParams {
  emailRequired: boolean;
  password: string;
  errorMessage?: string;
  onButtonClick: (request: PasswordChangeRequestInternal) => void;
}

export interface PasswordChangeRequestInternal {
  password: string;
  newPassword: string;
  confirmPassword: string;
  email: string;
}

export const ChangePasswordComponnent = (
  props: ChangePasswordParams,
): JSX.Element => {
  const [state, setState] = useState<PasswordChangeRequestInternal>({
    password: '',
    newPassword: '',
    confirmPassword: '',
    email: '',
  });

  const passwordValidation = validatePassword(state.newPassword);

  const updateField = (
    field: keyof PasswordChangeRequestInternal,
    value?: string,
  ): void => {
    const newObj = {
      ...state,
      [field]: value,
    };
    setState(newObj);
  };

  const isEmailInCorrectFormat = !state.email ? true : isEmail(state.email);
  const oldPasswordMatch = !state.password
    ? true
    : props.password === state.password;
  const isNewPasswordFieldsMatch =
    !!state.newPassword && !!state.confirmPassword
      ? state.newPassword === state.confirmPassword
      : true;

  const isAllFieldFilled =
    !!state.password &&
    !!state.newPassword &&
    !!state.confirmPassword &&
    oldPasswordMatch &&
    isNewPasswordFieldsMatch &&
    (props.emailRequired ? !!state.email && isEmailInCorrectFormat : true) &&
    !passwordValidation.some((x) => !x.isValid);

  return (
    <PageContentContainer>
      <Box>
        <Typography variant="h5">Update your password </Typography>
        <Typography
          variant="h5"
          sx={{ paddingBottom: '33px', color: colors.neutral[600] }}
        >
          Enter your password below.
        </Typography>
      </Box>
      <Input
        onChange={(e) => updateField('password', e.target.value)}
        name="password"
        id="password"
        label="Old Password"
        size="medium"
        type="password"
        InputLabelProps={{ style: { fontSize: 18 } }}
        fullWidth
      />
      <ValidationBox show={!oldPasswordMatch} text="Check you password" />
      <Input
        onChange={(e) => updateField('newPassword', e.target.value)}
        name="new_password"
        id="new_password"
        label="New password"
        size="medium"
        type="password"
        InputLabelProps={{ style: { fontSize: 18 } }}
        fullWidth
        sx={{
          mt: '16px',
        }}
      />
      <ValidationBox
        show={!!props.errorMessage}
        text={props.errorMessage ?? ''}
      />
      <Box
        sx={{ padding: '5px 20px 0px 0px' }}
        visibility={true ? 'initial' : 'visible'}
        display={true ? 'initial' : 'none'}
      >
        {passwordValidation.map((element) => (
          <ValidationMessage
            key={`ValidationMessage-new_password-rules-${element.id}`}
            red={!element.isValid}
            text={element.message}
          />
        ))}
      </Box>
      <Input
        onChange={(e) => updateField('confirmPassword', e.target.value)}
        name="confirm_new_password"
        id="confirm_new_password"
        label="Confirm new password"
        size="medium"
        type="password"
        InputLabelProps={{ style: { fontSize: 18 } }}
        fullWidth
        sx={{
          mt: '16px',
        }}
      />
      <ValidationBox
        show={!isNewPasswordFieldsMatch}
        text="Passwords must match"
      />
      {props.emailRequired ? (
        <>
          <Input
            onChange={(e) => updateField('email', e.target.value)}
            name="email"
            id="email"
            label="Email address"
            size="medium"
            type="email"
            InputLabelProps={{ style: { fontSize: 18 } }}
            fullWidth
            sx={{
              mt: '16px',
            }}
          />
          <ValidationBox show={!isEmailInCorrectFormat} text="Invalid format" />
          <ValidationBox
            show
            text="If applicable, please use the same email address you currently use to access vRxPro, Pulse, or MyCovetrus."
            isRed={false}
          />
        </>
      ) : (
        <></>
      )}

      <Button
        variant="contained"
        onClick={() => props.onButtonClick(state)}
        sx={{ marginTop: '12px' }}
        disabled={!isAllFieldFilled}
      >
        Update password
      </Button>
    </PageContentContainer>
  );
};
