export enum Applications {
  // react
  prWizard = 'prwizard',
  petId = 'petid',
  practiceadministration = 'practiceadministration',

  // asp.net
  pfb = 'pfb',
  mediacenter = 'mediacenter',
  pfAdmin = 'pfadmin',
  varAdmin = 'varadmin',

  // blazor
  pulseConnectorManager = 'pulseconnectormanager',
}
