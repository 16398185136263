import React from 'react';
import { Box, Palette } from '@mui/material';
import ErrorIcon from './ErrorIcon';
import { theme } from '../../theme';
import * as styles from './styles';

const colors: Palette = theme.palette;

interface Props {
  text?: string;
  red: boolean;
  children?: JSX.Element[] | JSX.Element;
}

export default function ValidationMessage({
  red = true,
  text,
  children,
}: Props): JSX.Element {
  return (
    <Box sx={styles.warning}>
      <ErrorIcon
        fillColor={red ? colors.utility.danger[600] : colors.brand.primary[500]}
      />
      <Box sx={red ? styles.warningRed : styles.warningGrey}>
        {text}
        <br />
        {children}
      </Box>
    </Box>
  );
}
