import React, { useState } from 'react';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { Button } from '../../../../components/Buttons';
import { Input } from '../../../../components/Inputs';
import { colors } from '../../../../theme';
import { isEmail } from '../../../utils';
import { ValidationBox } from '../../../../components/ValidationMessage';
import { PageContentContainer } from '../../../../components/Container';

interface ForgotPasswordParams {
  errorMessage?: string;
  onButtonClick: (request: ForgotPasswordRequestInternal) => void;
}

export interface ForgotPasswordRequestInternal {
  userId: string;
  email: string;
}

export const ForgotPasswordComponnent = (
  props: ForgotPasswordParams,
): JSX.Element => {
  const [state, setState] = useState<ForgotPasswordRequestInternal>({
    userId: '',
    email: '',
  });

  const updateField = (
    field: keyof ForgotPasswordRequestInternal,
    value?: string,
  ): void => {
    const newObj = {
      ...state,
      [field]: value,
    };
    setState(newObj);
  };

  const isEmailInCorrectFormat = !state.email ? true : isEmail(state.email);

  const isAllFieldFilled =
    !!state.userId && !!state.email && isEmailInCorrectFormat;
  return (
    <PageContentContainer>
      <Box>
        <Typography variant="h5">Recover your password</Typography>
        <Typography
          variant="h5"
          sx={{ paddingBottom: '33px', color: colors.neutral[600] }}
        >
          Please enter your email address. If this matches an existing user, we
          will send a password recovery email to this address. The email will be
          valid for 10 minutes.
        </Typography>
      </Box>
      <Input
        onChange={(e) => updateField('userId', e.target.value)}
        name="userId"
        id="userId"
        label="User ID"
        size="medium"
        type="text"
        InputLabelProps={{ style: { fontSize: 18 } }}
        fullWidth
      />

      <Input
        onChange={(e) => updateField('email', e.target.value)}
        name="email"
        id="email"
        label="Email"
        size="medium"
        type="email"
        InputLabelProps={{ style: { fontSize: 18 } }}
        fullWidth
        sx={{
          mt: '16px',
        }}
      />
      <ValidationBox text="Invalid format" show={!isEmailInCorrectFormat} />
      <ValidationBox
        text={props.errorMessage ?? ''}
        show={!!props.errorMessage}
      />

      <Button
        variant="contained"
        onClick={() => props.onButtonClick(state)}
        sx={{ marginTop: '12px' }}
        disabled={!isAllFieldFilled}
      >
        Send Recovery Email
      </Button>
    </PageContentContainer>
  );
};
