import React, { KeyboardEvent } from 'react';
import { IconButton, Link, Typography } from '@mui/material';
import { Box } from '@mui/system';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Button } from '../../../../components/Buttons';
import { PageContentContainer } from '../../../../components/Container';
import { Input } from '../../../../components/Inputs';
import { ValidationMessage } from '../../../../components/ValidationMessage';
import { colors } from '../../../../theme';

interface RapportAuthEnterPasswordProps {
  password: string;
  validationFailed: boolean;

  onPasswordChanged: (value: string) => void;
  onNextButtonClicked: () => void;
  onForgotButtonClicked: () => void;
  onBackButtonClicked: () => void;
}

export const RapportAuthEnterPasswordComponent = ({
  password,
  validationFailed,
  ...props
}: RapportAuthEnterPasswordProps): JSX.Element => {
  const handleChange = (e): void => {
    props.onPasswordChanged(e.target.value);
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLElement>): void => {
    if (e.key === 'Enter') props.onNextButtonClicked();
  };

  const onButtonClick = (): void => {
    props.onNextButtonClicked();
  };

  const handleForgotPasswordClick = (): void => {
    props.onForgotButtonClicked();
  };

  return (
    <PageContentContainer>
      <Box>
        <IconButton
          onClick={props.onBackButtonClicked}
          sx={{ marginBottom: '16px' }}
        >
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h5">Please Sign In</Typography>
        <Typography
          variant="h5"
          sx={{ paddingBottom: '33px', color: colors.neutral[600] }}
        >
          Enter your password below.
        </Typography>
      </Box>
      <Input
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        name="password"
        id="password"
        label="Password"
        size="medium"
        type="password"
        value={password}
        InputLabelProps={{ style: { fontSize: 18 } }}
        fullWidth
      />
      <Box
        sx={{ padding: '5px 20px 0px 0px' }}
        visibility={validationFailed ? 'visible' : 'initial'}
        display={!validationFailed ? 'none' : 'initial'}
      >
        <ValidationMessage red>
          <>
            Invalid credentials - If the problem persists, please contact
            support at <Link href="tel:+855-980-9099">855-980-9099</Link> Option
            1
          </>
        </ValidationMessage>
      </Box>

      <Button
        variant="contained"
        onClick={onButtonClick}
        sx={{ marginTop: '16px' }}
        disabled={!password}
      >
        Next
      </Button>

      <Button
        variant="text"
        sx={{ marginTop: '26px' }}
        onClick={handleForgotPasswordClick}
      >
        Forgot password
      </Button>
    </PageContentContainer>
  );
};
